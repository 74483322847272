// ? Page where all users (teachers + students) are listed for admins. /panel/admin/users

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import '../../css/Users/listing.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/fontawesome-free-solid'
import axios from 'axios';
import { MutatingDots } from 'react-loader-spinner';

const Users_List = () => {
  const [users, setUsers] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const config = {
      method: 'post',
      url: 'https://lis-api.exion.xyz/api/admin/fetchstudents?type=all',
      withCredentials: true,
    }

    axios(config)
      .then(response => {
        console.log(response.data)
        if (response.data.valid === false) return navigate('/login');
        if (response.data.valid === true) return;
        if (response.data.admin === false) navigate('/results')
        if (response.data.admin === true) return setUsers(response.data.allusers);
      })
  }, []);

  return (
    <section className="main">
      <div className="users-container">
        <div className="header-bar">
          <p>Users</p>
          <div className="users-header-right">
            <input type="text" className="users-search" />
            <button className="users-search-btn"><FontAwesomeIcon icon={faSearch} /></button>
            <button className="users-create-btn" onClick={() => navigate('/panel/admin/users/create')}>Create New</button>
          </div>
        </div>

        <div className="users-table-container">
          {users ?

            (users.length > 0) ?

              <table className="users-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Email</th>
                    <th>Student Name</th>
                    <th>Academic Level</th>
                  </tr>
                </thead>
                <tbody>

                  {users.map((user) => {
                    return (

                      <tr>
                        <td>
                          <code>{user._id}</code>
                        </td>
                        <td>
                          <a href="https://laa.com">{user.email}</a>
                        </td>
                        <td className='name'>{user.first_name} {user.last_name}</td>
                        <td className='class'>{user.grade}</td>
                      </tr>

                    )

                  })}

                </tbody>
              </table>

              :
              <h3 style={{ "color": "#FF5555", "marginBottom": "1rem" }}>No users were found, you can create a user here</h3>

            :

            <div
              style={{
                height: "100",
                margin: "2rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                justifySelf: "center",
                alignItems: "center"
              }}
            >
              <MutatingDots color="#fff" secondaryColor='#01c38d' height="100" width="100" />
              <h2>Fetching Users</h2>
            </div>

          }

        </div>
      </div>
    </section>
  )
}

export default Users_List;