// ? This is the student listing for admins. /panel/students

import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from 'js-cookie';
import qs from 'qs';
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { MutatingDots } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/fontawesome-free-solid'
import '../css/Users/listing.css'
import Swal from 'sweetalert2';

const Admin_Student = (props) => {
    const [students, setStudents] = useState(null);
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        const config = {
            method: 'post',
            url: 'https://lis-api.exion.xyz/api/admin/fetchstudents?type=all',
            withCredentials: true,
        }

        axios(config)
            .then(response => {
                console.log(response.data)
                if (response.data.valid === false) return navigate('/login');
                if (response.data.valid === true) return;
                if (response.data.admin === false) navigate('/results')
                if (response.data.admin === true) return setStudents(response.data.allusers);
            })
    }, []);

    // useEffect(() => {
    //     const data = qs.stringify({
    //         session: Cookies.get('session')
    //     });

    //     const config = {
    //         method: 'post',
    //         url: 'https://lis-api.exion.xyz/api/admin/fetchstudents?type=all',
    //         withCredentials: true,
    //         data: data,
    //     };

    //     axios(config)
    //         .then(response => {
    //             console.log(response.data)
    //             if (!response.data.valid) return navigate('/login');
    //             if (response.data.valid) {
    //                 if (response.data.admin === false) navigate('/results');
    //                 if (response.data.admin === true) {
    //                     if (state.from === 'result success') {
    //                         Swal.fire({
    //                             toast: true,
    //                             position: "top-end",
    //                             timer: 5000,
    //                             timerProgressBar: true,
    //                             title: "Redirected",
    //                             text: `The changes you made in results were put into effect and you were redirected to the student search page!`,
    //                             icon: 'info'
    //                         })
    //                     }
    //                     return;
    //                 } else if (state.from === 'student-crt success') {
    //                     Swal.fire({
    //                         toast: true,
    //                         position: "top-end",
    //                         timer: 5000,
    //                         timerProgressBar: true,
    //                         title: "Redirected",
    //                         text: `The student was registered and you were redirected to the student search page!`,
    //                         icon: 'info'
    //                     })
    //                 };
    //             }
    //         });
    // }, [])

    let cancelToken;

    const fetchStudents = ((e) => {

        const input = document.getElementById('search');
        if (input.value === "") return;

        try {
            setStudents(null);
            const config = {
                method: "post",
                url: "https://lis-api.exion.xyz/api/admin/fetchstudents",
                withCredentials: true,
                data: `student_search=${input.value}`,
            }

            axios(config)
                .then(response => {
                    console.log(response.data.students)
                    if (response.data.valid === false) return navigate('/login');
                    if (response.data.valid === true) return;
                    if (response.data.admin === false) navigate('/results')
                    if (response.data.admin === true) setStudents(response.data.students);
                })
        } catch (error) {
            console.log(error)
        }
    });

    return (
        <section className="main">
            <div className="users-container">
                <div className="header-bar">
                    <p>Students</p>
                    <div className="users-header-right">
                        <input id="search" type="text" className="users-search" />
                        <button className="users-create-btn" onClick={() => fetchStudents()}>Search</button>
                    </div>
                </div>

                <div className="users-table-container">
                    {students ?

                        (students.length > 0) ?

                            <table className="users-table">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Email</th>
                                        <th>Student Name</th>
                                        <th>Academic Level</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {students.map((user) => {

                                        return (

                                            <tr>
                                                <td>
                                                    <code>{user._id}</code>
                                                </td>
                                                <td>
                                                    <a href="https://laa.com">{user.email}</a>
                                                </td>
                                                <td className='name'>{user.first_name} {user.last_name}</td>
                                                <td className='class'>{user.grade}</td>
                                            </tr>

                                        )

                                    })}

                                </tbody>
                            </table>

                            :
                            <h3 style={{ "color": "#FF5555", "marginBottom": "1rem" }}>No students were found, you can create a student here</h3>

                        :

                        <div
                            style={{
                                height: "100",
                                margin: "2rem",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                justifySelf: "center",
                                alignItems: "center"
                            }}
                        >
                            <MutatingDots color="#fff" secondaryColor='#01c38d' height="100" width="100" />
                            <h2>Fetching Users</h2>
                        </div>

                    }

                </div>
            </div>
        </section>
    );
}

export default Admin_Student;