import React, { useState } from 'react';
import axios from 'axios';
import { MutatingDots } from 'react-loader-spinner';
import useScript from '../hooks/useScript';
import $ from 'jquery';
import Card from '../components/Results/cards'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'
import qs from 'qs';
import { faSearch } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Results = () => {
  const navigate = useNavigate();

  const [processingResult, setProcessingResult] = useState(false);
  const [result, setResult] = useState(null);

  useEffect(() => {

    setProcessingResult(true);

    // ? Get latest result
    const config = {
      method: 'post',
      url: 'https://lis-api.exion.xyz/api/results/get',
      withCredentials: true,
      params: { type: "latest" }
    };

    axios(config)
      .then(response => {

        console.log(response.data)
        if (!response.data.valid) return navigate('/login');
        if (response.data.valid) {

          if (response.data.status === "ok") {

            setProcessingResult(false);
            setResult(response.data.result);

          }

          // if (response.data.status === "ok-noresult") {



          // }

        }
      }).catch(err => console.log(err))

  }, [])

  // FETCHING RESULTS (API CALL TO LISLAGOS)

  const handleResult = (e) => {

    setProcessingResult(true);

    const obj = {
      type: "student-self-query",
      session: document.getElementById('session').value,
      term: document.getElementById('term').value
    }

    const config = {
      method: 'post',
      url: 'https://lis-api.exion.xyz/api/results/get',
      withCredentials: true,
      params: obj
    }

    axios(config)
      .then(response => {
        console.log(response.data)
        if (response.data.valid === false) return navigate('/login');
        if (response.data.valid === true) {

          if (response.data.status === "ok") {

            setProcessingResult(false);

            if (response.data?.message === "no-result") return setResult("no-result")

            setResult(response.data.result);

          }

        }

      })

  }

  return (
    <section className="main">
      <div className="users-container">
        <div className="header-bar">
          <p>Results</p>
          <div className="users-header-right">
            <select className="adjust" style={{ "margin": "0", "marginRight": "10px", "minWidth": "auto", "paddingRight": "0.7rem", "borderRight": "1rem solid transparent" }} name="session" id="session">
              <option value="2022-2023">Current (2022-2023)</option>
              <option value="2021_2022">2021-2022</option>
              <option value="2020_2021">2020-2021</option>
              <option value="2019_2020">2019-2020</option>
              <option value="2018_2019">2018-2019</option>
              <option value="2017_2018">2017-2018</option>
              <option value="2016_2017">2016-2017</option>
            </select>
            <select className="adjust" style={{ "margin": "0", "marginRight": "10px", "minWidth": "auto", "paddingRight": "0.7rem", "borderRight": "1rem solid transparent" }} name="term" id="term">
              <option value="1">Term 1</option>
              <option value="2">Term 2</option>
              <option value="3">Term 3</option>
            </select>
            <button style={{ "border": "none", "borderRadius": "3px" }} className="users-create-btn" onClick={() => handleResult()}>View Result</button>
          </div>
        </div>

        <div className="users-table-container">

          {
            processingResult ?

              <div
                style={{
                  height: "100",
                  margin: "2rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  justifySelf: "center",
                  alignItems: "center"
                }}
              >
                <MutatingDots color="#fff" secondaryColor='#01c38d' height="100" width="100" />
                <h2>Getting latest result..</h2>
              </div>

              :

              (result !== "no-result" && result !== null) ?

                (result.type === "single-term") ?

                  <table className="users-table">
                    <thead>
                      <tr>
                        <th>Subject</th>
                        <th>Credit</th>
                        {result.assessments.includes("test") ? <th>Test</th> : ""}
                        {result.assessments.includes("exam") ? <th>Exam</th> : ""}
                        {(result.assessments.includes("test") && result.assessments.includes("exam")) ? <th>Average</th> : ""}
                        {(result.assessments.includes("test") || result.assessments.includes("exam")) ? <th>Pos.</th> : ""}
                        {(result.assessments.includes("test") || result.assessments.includes("exam")) ? <th>Remark</th> : ""}
                      </tr>
                    </thead>
                    <tbody>

                      {result.subjects.map((subject) => {
                        return (

                          <tr>
                            <td>{subject.name}</td>
                            <td>2</td>
                            {result.assessments.includes("test") ? <td id={`${subject.name}_test`}>{subject.test !== null ? subject.test : "N/A"}</td> : ""}
                            {result.assessments.includes("exam") ? <td id={`${subject.name}_exam`}>{subject.exam !== null ? subject.exam : "N/A"}</td> : ""}
                            {(result.assessments.includes("test") && result.assessments.includes("exam")) ? <td id={`${subject.name}_avg`}>{subject.average}</td> : ""}
                            {(result.assessments.includes("test") || result.assessments.includes("exam")) ? <td>{(subject.class_position !== undefined && subject.class_position !== 0) ? `${subject.class_position}/${subject.position_out_of}` : "N/A"}</td> : ""}
                            {(result.assessments.includes("test") || result.assessments.includes("exam")) ? <td id={`${subject.name}_remark`}>{subject.remark}</td> : ""}
                          </tr>

                        )

                      })}

                    </tbody>
                  </table>

                  :

                  (result.type === "session-overall") ?


                    <table className="users-table">
                      <thead>
                        <tr>
                          <th>Subject</th>
                          <th>Credit</th>
                          <th>Test</th>
                          <th>Exam</th>
                          {/* <th>Average</th> */}
                          <th>1st Term Avg</th>
                          <th>2nd Term Avg</th>
                          <th>Overall Avg</th>
                          <th>Overall Pos</th>
                          <th>Remark</th>
                        </tr>
                      </thead>
                      <tbody>

                        {result.subjects.map((subject) => {
                          return (

                            <tr>
                              <td>{subject.name}</td>
                              <td>2</td>
                              <td id={`${subject.name}_test`}>{subject.term3_test !== null ? subject.term3_test : "N/A"}</td>
                              <td id={`${subject.name}_exam`}>{subject.term3_exam !== null ? subject.term3_exam : "N/A"}</td>
                              {/* <td id={`${subject.name}_avg`}>{subject.term3_avg}</td> */}
                              <td id={`${subject.name}_term1_avg`}>{subject.term1_avg !== null ? subject.term1_avg : "N/A"}</td>
                              <td id={`${subject.name}_term2_avg`}>{subject.term2_avg !== null ? subject.term2_avg : "N/A"}</td>
                              <td id={`${subject.name}_overall_avg`}>{subject.overall_average !== null ? subject.overall_average: "N/A"}</td>
                              <td>{(subject.class_position !== undefined && subject.class_position !== 0) ? `${subject.class_position}/${subject.position_out_of}` : "N/A"}</td>
                              <td id={`${subject.name}_remark`}>{subject.remark}</td>
                            </tr>

                          )

                        })}

                      </tbody>
                    </table>

                    :

                    ""

                :

                <h4>No result found!</h4>
          }

        </div>
      </div>
    </section>
  );
};

export default Results;