// ? Page where students/parents can contact teachers
// ! WORK IN PROGRESS

import '../../css/Chat/teachers.css'

const Teachers = () => {
  return (
    <section className="main">
      <h1>Teachers</h1>
      <div className="teachers-container">
        <div className="teacher-card">
          <img src="https://exion.xyz/lis.png" alt="Teacher" />
          <div className="teachers-card-info">
            <h3>Ihab Fakher</h3>
            <h4>Subjects: Mathematics</h4>
            <h4>Available hours: 8:30 - 9:30</h4>
          </div>
          <button>Chat now!</button>
        </div>
        <div className="teacher-card">
          <img src="https://exion.xyz/lis.png" alt="Teacher" />
          <div className="teachers-card-info">
            <h3>Ihab Fakher</h3>
            <h4>Subjects: Mathematics</h4>
            <h4>Available hours: 8:30 - 9:30</h4>
          </div>
          <button>Chat now!</button>
        </div>
        <div className="teacher-card">
          <img src="https://exion.xyz/lis.png" alt="Teacher" />
          <div className="teachers-card-info">
            <h3>Ihab Fakher</h3>
            <h4>Subjects: Mathematics</h4>
            <h4>Available hours: 8:30 - 9:30</h4>
          </div>
          <button>Chat now!</button>
        </div>
        <div className="teacher-card">
          <img src="https://exion.xyz/lis.png" alt="Teacher" />
          <div className="teachers-card-info">
            <h3>Ihab Fakher</h3>
            <h4>Subjects: Mathematics</h4>
            <h4>Available hours: 8:30 - 9:30</h4>
          </div>
          <button>Chat now!</button>
        </div>
        <div className="teacher-card">
          <img src="https://exion.xyz/lis.png" alt="Teacher" />
          <div className="teachers-card-info">
            <h3>Ihab Fakher</h3>
            <h4>Subjects: Mathematics</h4>
            <h4>Available hours: 8:30 - 9:30</h4>
          </div>
          <button>Chat now!</button>
        </div>
      </div>
    </section>
  )
}

export default Teachers