// ? Page where teachers/admins can edit a student's result 

import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { MutatingDots } from 'react-loader-spinner';
import '../../css/Users/create_users.css'
import Swal from 'sweetalert2';

const Results_editor = () => {
    const [processing, setProcessing] = useState(false);
    const [results, setResults] = useState(null);
    const [processedAvgRem, setProcessedAvgRem] = useState(false);

    const navigate = useNavigate();
    const { result_id, student_id } = useParams();

    useEffect(() => {
        setProcessing(true);


        // ? VERIFY IF RESULT EXISTS AND FETCH THE RESULT
        const fetchResult = {
            method: 'post',
            url: `https://lis-api.exion.xyz/api/results/get`,
            withCredentials: true,
            params: {
                type: "student",
                student_id,
                result_id
            }
        }

        axios(fetchResult)
            .then(response => {
                console.log("okay", response.data)
                if (response.data.valid === false) return navigate('/login');
                if (response.data.valid === true) return;
                if (response.data.admin === false) navigate('/results')
                if (response.data.admin === true) {
                    setResults(response.data.result);
                    setProcessing(false);
                    console.log(response.data);
                };
            })

        // ! Depreciated 
        // GET USER RESULTS
        // const config = {
        //     method: 'post',
        //     url: `https://lis-api.exion.xyz/api/admin/fetchstudents?student=${stid}`,
        //     withCredentials: true,
        // }

        // axios(config)
        //     .then(response => {
        //         console.log("okay", response.data)
        //         if (response.data.valid === false) return navigate('/login');
        //         if (response.data.valid === true) return;
        //         if (response.data.admin === false) navigate('/results')
        //         if (response.data.admin === true) {
        //             // Getting results list ready
        //             if (response.data.student.level === "senior") {
        //                 console.log("double he")
        //                 // Common subjects
        //                 const subjects = [{ name: "Math", marks: null }, { name: "Biology", marks: null },
        //                 { name: "Physics", marks: null }, { name: "Chemistry", marks: null },
        //                 { name: "English", marks: null }, { name: "Economics", marks: null }]
        //                 // Languages
        //                 if (response.data.student.second_language === "fr") {
        //                     subjects.push({ name: "French", marks: null });
        //                     console.log("lgnag")
        //                 } else subjects.push({ name: "Arabic", marks: null })

        //                 console.log("pushed past")
        //                 // Special subject
        //                 if (response.data.student.special_subject === "cs") subjects.push({ name: "Computer Science", marks: null })
        //                 if (response.data.student.special_subject === "pe") subjects.push({ name: "Physical Education", marks: null })
        //                 if (response.data.student.special_subject === "art") subjects.push({ name: "Art & Crafts", marks: null })
        //                 if (response.data.student.special_subject === "dt") subjects.push({ name: "Design & Technology", marks: null })

        //                 console.log(subjects)
        //                 setSubjects(subjects);
        //             }


        //             const resultCompleteRequest = {
        //                 method: 'post',
        //                 url: `https://lis-api.exion.xyz/api/results/getresult?student=${stid}&result=${result}`,
        //                 withCredentials: true,
        //             }

        //             axios(resultCompleteRequest)
        //                 .then(response => {
        //                     console.log("okay", response.data)
        //                     if (response.data.valid === false) return navigate('/login');
        //                     if (response.data.valid === true) return;
        //                     if (response.data.admin === false) navigate('/results')
        //                     if (response.data.admin === true) {
        //                         setProcessing(false);
        //                         console.log(response.data);
        //                     };
        //                 })
        //         };
        //     })
    }, []);

    let averages = [];
    const marksHandler = (assessment, subject) => {

        // ? If row is for test marks, then handle marks output for each subject
        if (assessment === "tests") {

            // ? If this result_group was created for this assessment type (tests) then fetch existing marks for each subject from "results.subjects" subobject in result document in database
            if (assessment === results.assessment) {

                return <input id={"marks_" + subject.name} onChange={(e) => averageHandler("marks_input", subject.name, assessment, e)} type="number" min="0" max="100" className="results-edit-marks" defaultValue={subject.mark} />;

            }

            // ? If not, then check if relevant session/grade/assessment/term results exist (test results as this result group is for exams).
            if (!results.test_results) {

                // ? Pushes average to averages array
                averages.push({ subject: subject.name, average: (subject.mark !== null && subject.mark !== undefined) ? subject.mark : "N/A" })

                // ? Render all test results as not defined if relevant test result does not exist.
                return "N/A";

            }
            // ? If exists, then check if each subject corresponds with one another and render marks
            else {

                const test_subject = results.test_results.subjects.find(s => { return s.name === subject.name });
                if (test_subject.mark !== null && test_subject.mark !== undefined) {

                    const exam_mark = (subject.mark !== null && subject.mark !== undefined) ? subject.mark : test_subject.mark;
                    averages.push({ subject: subject.name, average: (exam_mark + test_subject.mark) / 2 })

                    return `${test_subject.mark}`;

                } else {

                    averages.push({ subject: subject.name, average: (subject.mark !== null && subject.mark !== undefined) ? subject.mark : "N/A" })

                    return "N/A"

                }

            }

        }



        // ? If row is for exam marks, then handle marks output for each subject
        if (assessment === "exams") {

            // ? If this result_group was created for this assessment type (exams) then fetch existing marks for each subject from "results.subjects" subobject in result document in database
            if (assessment === results.assessment) {

                return <input id={"marks_" + subject.name} onChange={(e) => averageHandler("marks_input", subject.name, assessment, e)} type="number" min="0" max="100" className="results-edit-marks" defaultValue={subject.mark} />;

            }

            // ? If not, then check if relevant session/grade/assessment/term results exist (exam results as this result group is for tests).
            if (!results.exam_results) {

                // ? Renders average
                averages.push({ subject: subject.name, average: (subject.mark !== null && subject.mark !== undefined) ? subject.mark : "N/A" })

                // ? Render all test results as not defined if relevant test result does not exist.
                return "N/A";

            }
            // ? If exists, then check if each subject corresponds with one another and render marks
            else {

                const exam_subject = results.exam_results.subjects.find(s => { return s.name === subject.name });

                if (exam_subject.mark !== null && exam_subject.mark !== undefined) {

                    const test_mark = (subject.mark !== null && subject.mark !== undefined) ? subject.mark : exam_subject.mark;
                    averages.push({ subject: subject.name, average: (test_mark + exam_subject.mark) / 2 })

                    return `${exam_subject.mark}`;

                } else {

                    averages.push({ subject: subject.name, average: (subject.mark !== null && subject.mark !== undefined) ? subject.mark : "N/A" })

                    return "N/A"

                }

            }

        }

    }

    let remarks = [];
    const averageHandler = (type, subject, assessment, input) => {
        // ? Average that is calculated on page load/initial table render
        if (type === "onload") {
            if (processedAvgRem) return;

            const subject_avg = averages.find(result => { return result.subject === subject.name });

            try {

                if (subject_avg) {

                    document.getElementById(`${subject.name}_avg`).innerHTML = subject_avg.average;

                    if (subject_avg.average === "N/A") remarks.push({ subject: subject.name, remark: "N/A" });

                    if (0 <= subject_avg.average && subject_avg.average < 50) remarks.push({ subject: subject.name, remark: "FAIL" });
                    if (50 <= subject_avg.average && subject_avg.average < 70) remarks.push({ subject: subject.name, remark: "FAIR" });
                    if (70 <= subject_avg.average && subject_avg.average < 80) remarks.push({ subject: subject.name, remark: "GOOD" });
                    if (80 <= subject_avg.average && subject_avg.average < 90) remarks.push({ subject: subject.name, remark: "VERY GOOD" });
                    if (90 <= subject_avg.average && subject_avg.average <= 100) remarks.push({ subject: subject.name, remark: "EXCELLENT" });

                }

            } catch (err) {

                console.log(err);

            }

        }



        if (type === "marks_input") {

            const input_value = parseInt(input.target.value)

            if (input_value < 0 || input_value > 100) return input.target.classList.add("error-inp");

            const subject_avg_row = document.getElementById(`${subject}_avg`);

            if (input.target.value === "" && (document.getElementById(`${subject}_exam`).innerHTML) === "N/A" || document.getElementById(`${subject}_test`).innerHTML === "N/A") {

                document.getElementById(`${subject}_remark`).innerHTML = "N/A";

            };

            input.target.classList.remove("error-inp")

            if (subject_avg_row) {

                if (assessment === "tests") {

                    let exam_marks = parseInt(document.getElementById(`${subject}_exam`).innerHTML);

                    if (document.getElementById(`${subject}_exam`).innerHTML === "N/A") exam_marks = input_value;

                    if (input.target.value === "" && document.getElementById(`${subject}_exam`).innerHTML !== "N/A") {

                        averages = averages.filter(avg => avg.subject !== subject);
                        averages.push({ subject, average: exam_marks });

                        console.log(averages);

                        return subject_avg_row.innerHTML = exam_marks
                    }

                    if (input.target.value === "" && document.getElementById(`${subject}_exam`).innerHTML === "N/A") {

                        averages = averages.filter(avg => avg.subject !== subject);
                        averages.push({ subject, average: "N/A" });

                        console.log(averages);

                        return subject_avg_row.innerHTML = "N/A"
                    };

                    subject_avg_row.innerHTML = (exam_marks + input_value) / 2;
                    averages = averages.filter(avg => avg.subject !== subject);
                    averages.push({ subject, average: (exam_marks + input_value) / 2 });
                    console.log(averages);

                }

                if (assessment === "exams") {

                    let test_marks = parseInt(document.getElementById(`${subject}_test`).innerHTML);

                    if (document.getElementById(`${subject}_test`).innerHTML === "N/A") test_marks = input_value;

                    if (input.target.value === "" && document.getElementById(`${subject}_test`).innerHTML !== "N/A") return subject_avg_row.innerHTML = test_marks;

                    if (input.target.value === "" && document.getElementById(`${subject}_test`).innerHTML === "N/A") return subject_avg_row.innerHTML = "N/A";

                    subject_avg_row.innerHTML = (test_marks + input_value) / 2;

                }

                console.log(subject, subject_avg_row);

                if (subject_avg_row !== "N/A") {

                    const subject_average = parseInt(subject_avg_row.innerHTML);

                    if (0 <= subject_average && subject_average < 50) {
                        remarks = remarks.filter(remark => remark.subject !== subject);
                        remarks.push({ subject, remark: "FAIL" });
                        return document.getElementById(`${subject}_remark`).innerHTML = "FAIL"
                    }

                    if (50 <= subject_average && subject_average < 70) {
                        remarks = remarks.filter(remark => remark.subject !== subject);
                        remarks.push({ subject, remark: "FAIR" });
                        return document.getElementById(`${subject}_remark`).innerHTML = "FAIR"
                    }

                    if (70 <= subject_average && subject_average < 80) {
                        remarks = remarks.filter(remark => remark.subject !== subject);
                        remarks.push({ subject, remark: "GOOD" });
                        return document.getElementById(`${subject}_remark`).innerHTML = "GOOD"
                    }

                    if (80 <= subject_average && subject_average < 90) {
                        remarks = remarks.filter(remark => remark.subject !== subject);
                        remarks.push({ subject, remark: "GOOD" });
                        return document.getElementById(`${subject}_remark`).innerHTML = "VERY GOOD"
                    }

                    if (90 <= subject_average && subject_average <= 100) {
                        remarks = remarks.filter(remark => remark.subject !== subject);
                        remarks.push({ subject, remark: "EXCELLENT" });
                        return document.getElementById(`${subject}_remark`).innerHTML = "EXCELLENT"
                    }

                }

            }

        }

    }




    const remarksHandler = (type, subject) => {

        if (type === "onload") {
            if (processedAvgRem) return;

            const subject_remark = remarks.find(remark => { return remark.subject === subject });

            try {

                if (subject_remark) {

                    document.getElementById(`${subject}_remark`).innerHTML = subject_remark.remark;

                }

            } catch (err) {

                console.log(err);

            }

        }

    }


    const saveResult = () => {

        if (document.getElementsByClassName('error-inp').length) return;

        setProcessedAvgRem(true);
        setProcessing(true);


        // ? Set to processing while request completes

        // ? Arrange data to send
        const mark_inputs = Array.from(document.getElementsByClassName("results-edit-marks"));

        let subjects = [];
        mark_inputs.forEach(input => {

            const subject = input.id.replace("marks_", "")
            let mark;

            if (input.value === "") {
                mark = null;
            } else mark = parseInt(input.value);

            const object = {
                name: subject,
                mark
            }

            subjects.push(object);
        })

        // ? Make request to save result
        const saveResult = {
            method: 'post',
            url: `https://lis-api.exion.xyz/api/results/save`,
            withCredentials: true,
            params: {
                type: "student",
                result_id,
                student_id,
                subjects
            }
        }

        axios(saveResult)
            .then(response => {
                console.log("okay", response.data)
                if (response.data.valid === false) return navigate('/login');
                if (response.data.valid === true) return;
                if (response.data.admin === false) navigate('/results')
                if (response.data.admin === true) {
                    if (response.data.status === "ok") {
                        return setProcessing(false)
                    };

                    if (response.data.status === "warn") {
                        setProcessing(false)
                        Swal.fire(
                            'Result saved!',
                            'This student\'s results have been updated but the result for this class will have to be republished',
                            'info'
                        )
                    }
                };
            })

    }

    const deleteResult = () => {

        const config = {
            method: 'post',
            url: `https://lis-api.exion.xyz/api/results/delete`,
            withCredentials: true,
            params: {
                type: "student",
                result_id,
                student_id
            }
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                setProcessing(true);

                axios(config)
                    .then(response => {
                        console.log("okay", response.data)
                        if (response.data.valid === false) return navigate('/login');
                        if (response.data.valid === true) return;
                        if (response.data.admin === false) navigate('/results')
                        if (response.data.admin === true) {
                            if (response.data.status === "ok") {
                                setProcessing(false)
                                Swal.fire({
                                    title: `Result Deleted!`,
                                    text: `This result has been sent into deletion state and will be deleted in 30 days.`,
                                    icon: 'success',
                                    allowOutsideClick: () => navigate(`/panel/results/${result_id}`)
                                }).then(() => {
                                    navigate(`/panel/results/${result_id}`)
                                });
                            };
                        };
                    })


            }
        })

    }

    return (
        <section className="main">
            {processing ?
                <div className="processing-overlay">
                    <div
                        style={{
                            height: "100",
                            margin: "2rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            justifySelf: "center",
                            alignItems: "center"
                        }}
                    >
                        <MutatingDots color="#fff" secondaryColor='#01c38d' height="100" width="100" />
                        <h2>Hold on</h2>
                    </div>
                </div>
                :
                ""
            }
            <div className="containers-wrapper">

                <div className="users-container">
                    <div className="header-bar">
                        <p>{results ? `${results.student_name}'s` : ""} Results</p>
                    </div>

                    <div className="users-table-container">
                        {results ?

                            (results.subjects.length > 0) ?

                                <table className="users-table">
                                    <thead>
                                        <tr>
                                            <th>Subject</th>
                                            <th>C</th>
                                            <th>Test</th>
                                            <th>Exam</th>
                                            <th>Average</th>
                                            <th>Remark</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {results.subjects.map((subject) => {
                                            return (

                                                <tr>
                                                    <td>{subject.name}</td>
                                                    <td>2</td>
                                                    <td id={`${subject.name}_test`}>{marksHandler("tests", subject)}</td>
                                                    <td id={`${subject.name}_exam`}>{marksHandler("exams", subject)}</td>
                                                    <td id={`${subject.name}_avg`}>{averageHandler("onload", subject)}</td>
                                                    <td id={`${subject.name}_remark`}>{remarksHandler("onload", subject.name)}</td>
                                                </tr>

                                            )

                                        })}

                                    </tbody>
                                </table>

                                : ""

                            : ""}
                    </div>
                </div>
            </div>

            {results ?

                <div style={{ "marginTop": "3rem" }} className="users-container">
                    <div className="header-bar">
                        <p>Actions</p>
                    </div>
                    <div className="allactions-container">
                        <div className="action-container">
                            <div className="action-details">
                                <h1>Save result</h1>
                                <p>Ater you finish editting the results, you can save it and publish it at a later time.</p>
                            </div>
                            <button onClick={() => saveResult()} style={{ "marginTop": "0" }} className="submit">Save</button>
                        </div>
                        <div className="action-container">
                            <div className="action-details">
                                <h1>Delete result</h1>
                                <p>This will remove this student's existing results from this session.</p>
                            </div>
                            <button onClick={() => deleteResult()} style={{ "marginTop": "0", "background": "#FF5555" }} className="submit">Delete</button>
                        </div>
                    </div>
                </div>

                : ""}

        </section>
    )
}

export default Results_editor