import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { MutatingDots } from 'react-loader-spinner';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap, faScroll, faMessage, faEnvelopesBulk, faUserFriends, faGears, faRightFromBracket, faChartLine } from '@fortawesome/free-solid-svg-icons'
import fontawesome from '@fortawesome/fontawesome'

fontawesome.library.add(faGraduationCap, faScroll, faMessage, faEnvelopesBulk, faUserFriends, faGears, faRightFromBracket, faChartLine);

const Navigation = () => {
    const location = useLocation();
    const navigate = useNavigate(); // once ready it returns the 'window.location' object
    const [url, setUrl] = useState(null);
    const [admin, setAdmin] = useState(null);
    const [name, setName] = useState({ first_name: "LIS", last_name: "Student" });

    useEffect(() => {
        const config = {
            method: 'post',
            url: 'https://lis-api.exion.xyz/api/services/sessions',
            withCredentials: true,
        };

        axios(config)
            .then(response => {
                console.log(response.data)
                if (!response.data.valid) return navigate('/login');
                if (response.data.valid) {
                    if (response.data.admin === true) return setAdmin(true);
                    if (response.data.admin === false) {
                        setAdmin(false);
                        if (response.data.first_name && response.data.last_name) return setName({ first_name: response.data.first_name, last_name: response.data.last_name })
                    }
                };
            })
    }, []);

    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);

    const handleLogout = () => {
        console.log("hi")
        const config = {
            method: 'post',
            url: 'https://lis-api.exion.xyz/api/account/logout',
            withCredentials: true,
        }

        axios(config)
        .then(response => { 
            console.log(response.data)
            if (response.data.status === 'ok') {
                console.log("LOGGED")
                navigate('/login');
            }
        })
    }

    return (
        <div>
            <nav className="navbar">
                <div className="container">
                    {(admin === null) ?
                        <div
                            style={{
                                height: "100",
                                margin: "2rem",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                justifySelf: "center",
                                alignItems: "center"
                            }}
                        >
                            <MutatingDots color="#fff" secondaryColor='#191e29' height="100" width="100" />
                            <h2>Hang on</h2>
                        </div>
                        : (admin === false) ?

                            <>
                                {/* <div className="header">
                                    <h2>LIS Portal</h2>
                                    <img src="https://exion.xyz/lis.png" alt="" className="" />
                                    <h3>{`${name.first_name} ${name.last_name}`}</h3>
                                </div> */}
                                <div className="nav">
                                    <h4>NAVIGATION</h4>
                                    <ul>
                                        <NavLink to='/results' className={"nav-link" + (url === '/results' ? " active" : "")}><FontAwesomeIcon icon="scroll" style={{ "marginRight": ".75rem" }}/>Results</NavLink>
                                        <NavLink to='/achievements' className={"nav-link" + (url === '/achievements' ? " active" : "")}><FontAwesomeIcon icon="chart-line" style={{ "marginRight": ".75rem" }}/>Performance</NavLink>
                                        <NavLink to='/contact' className={"nav-link" + (url === '/contact' ? " active" : "")}><FontAwesomeIcon icon="message" style={{ "marginRight": ".75rem" }}/>Chat</NavLink>
                                        <a className='nav-link nav-logout' onClick={handleLogout}><FontAwesomeIcon icon="right-from-bracket" style={{ "marginRight": ".75rem" }}/>Logout</a>
                                    </ul>
                                </div>
                            </>
                            :
                            <>
                                <h4>MANAGEMENT</h4>
                                <div className="nav">
                                    <ul>
                                        <NavLink to='/panel/students' className={"nav-link" + (url === '/panel/students' ? " active" : "")}><FontAwesomeIcon icon="graduation-cap" style={{ "marginRight": ".75rem" }}/>Students</NavLink>
                                        <NavLink to='/panel/results' className={"nav-link" + (url === '/panel/results' ? " active" : "")}><FontAwesomeIcon icon="scroll" style={{ "marginRight": ".75rem" }}/>Results</NavLink>
                                        <NavLink to='/panel/teachers' className={"nav-link" + (url === '/panel/staff' ? " active" : "")}><FontAwesomeIcon icon="message" style={{ "marginRight": ".75rem" }}/>Chat</NavLink>
                                    </ul>
                                </div>
                                <h4>PANEL SETTINGS</h4>
                                <div className="nav">
                                    <ul>
                                        <NavLink to='/panel/admin/email' className={"nav-link" + (url === '/panel/admin/email' ? " active" : "")}><FontAwesomeIcon icon="envelopes-bulk" style={{ "marginRight": ".75rem" }}/>Email</NavLink>
                                        <NavLink to='/panel/admin/users' className={"nav-link" + (url === '/panel/admin/users' ? " active" : "")}><FontAwesomeIcon icon="user-friends" style={{ "marginRight": ".75rem" }}/>Users</NavLink>
                                        <NavLink to='/panel/admin/other' className={"nav-link" + (url === '/panel/admin/other' ? " active" : "")}><FontAwesomeIcon icon="gears" style={{ "marginRight": ".75rem" }}/>Other</NavLink>
                                        <a className='nav-link nav-logout' onClick={handleLogout}><FontAwesomeIcon icon="right-from-bracket" style={{ "marginRight": ".75rem" }}/>Logout</a>
                                    </ul>
                                </div>
                            </>
                    }
                </div>
                {/* <p>Made with <i className="fa fa-solid fa-heart"></i> by Chall</p> */}
                <p style={{ "fontSize": "13px" }}>Powered by Exion</p>
            </nav>
        </div>
    )
};

export default Navigation;