// ? This page controls all the routes.

import Results from '../pages/Results'
import Navigation from '../components/Navigation'
import Admin_Student from '../pages/Admin_Students'
import Results_Search from '../pages/Results/Results_list';
import Teachers from '../pages/Chat/Teachers'
import Users_List from '../pages/Users/Users_List';
import Create_User from '../pages/Users/Create_User';
import Results_create from '../pages/Results/Results_create';
import Results_editor from '../pages/Results/Results_editor';
import Results_view from '../pages/Results/Results_view';

const Content = (props) => {
    // ! Some of these routes are depreciated, remove depreciated routes.
    
    // ? Routes to the result viewing page for students.
    if (props.targ === "results") {
        return <Results />;
    } 
    // ? Routes to blank page with just navigation bar.
    // TODO Make a home page for it to route to
    else if (props.targ === "/") {
       return <Navigation />
    } 
    // ? Routes to the student listing page for admins
    else if (props.targ === "admin_students") {
        return <Admin_Student />
    } 
    // ? Routes to the results listing page for admins
    else if (props.targ === 'admin_results_search') {
        return <Results_Search />
    } 
    // ? Routes to contact page for students/parents
    // ! WORK IN PROGRESS
    else if (props.targ === "contact") {
        return <Teachers />
    } 
    // ? Routes to users list (teachers + students) for admins
    else if (props.targ === "panel_users") {
        return <Users_List />
    } 
    // ? Routes to user registration page for admins
    else if (props.targ === "panel_users_create") {
        return <Create_User />
    } 
    // ? Routes to results creation page for admins
    else if (props.targ === "admin_results_create") {
        return <Results_create />
    } 
    // ? Page where teacher/admin can see result group
    else if (props.targ === "admin_results_view") {
        return <Results_view />
    }
    else if (props.targ === "admin_results_edit") {
        return <Results_editor />
    }
};

export default Content;
