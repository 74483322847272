import { useState, useEffect } from 'react';
import '../css/login.css'
import axios from 'axios';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'
import qs from 'qs';

const Login = () => {
    const navigation = useNavigate();
    
    // Check if user is already logged in

    useEffect(() => {
        const data = qs.stringify({
            session: Cookies.get('session')
        });

        const config = {
            method: 'post',
            url: 'https://lis-api.exion.xyz/api/services/sessions',
            withCredentials: true,
            data : data,
          };

        axios(config)
        .then(response => {
            console.log(response.data)
            if (!response.data.valid) return;
            if (response.data.valid) return navigation('/results')
        });
    });

    const [errors, setErrors] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleLogin = ((e) => {
        const $form = $('#login')
        e.preventDefault();

        const button = document.getElementById('lsubmit');
        const icon = document.getElementById('icon');
    
        button.classList.add('disabled');
        icon.setAttribute('class', 'fas fa-lock icon-left');

        const options = {
            method: 'post',
            url: 'https://lis-api.exion.xyz/api/user/login',
            withCredentials: true,
            data: $form.serialize(),
        }

        console.log($form.serialize())

        axios(options)
            .then(response => {

                button.classList.remove('disabled');
                icon.setAttribute('class', 'fa fa-unlock')

                console.log(response.data)
                if (response.data.status === "ok") {
                    // Remove all previous errors
                    setErrors(null);
                    // Update success state to true
                    setSuccess(true);
                    // Redirect user to page
                    setTimeout(() => {
                        navigation('/results')
                    }, 1500);
                };
                if (response.data.status === "error") { 
                    // Update error state
                    setErrors(response.data)
                };
            })
    });

    return (
        <div className='yoho'>
            <div className="login-container">
                <form className="login-form" id="login">
                    <div className="login-header">
                        <img src="https://exion.xyz/lis.png" alt="logo" />
                        <h1>LIS</h1>
                    </div>
                    {(success !== null) ? (success === true) ? <h4 className='success'>Logging you in..</h4>:<h4 className='main-error'>Logging you in..</h4>:""}
                    <label htmlFor="id">Your ID</label>
                    {errors ? (errors.id) ? <label className="error" htmlFor="password">{errors.id}</label>:"":""}
                    <input type="text" name="id" id="id" placeholder='ID' />
                    <label htmlFor="password">Your password</label>
                    {errors ? (errors.password) ? <label className="error" htmlFor="password">{errors.password}</label>:"":""}
                    <input type="password" name="password" id="password" placeholder='Password' />
                    <div className="rme">
                        <input type="checkbox" name="rme" id="rme" value="true"/>
                        <label htmlFor="rme">Remember this device</label>
                    </div>
                    <button id="lsubmit" onClick={(e) => handleLogin(e)}><i id="icon" className="fa fa-unlock"></i> Login</button>
                    <p>Forgot password? <a href='#'>Get some help</a></p>
                </form>
            </div>
        </div>
    );
};

export default Login;