const Card = (props) => {
    console.log(props);
    return (
        <div className="card">
            {/* Just costant values for texting the layout of page */}
            <h3>{props.subject.name}</h3>
            <div className="line"></div>
            <p>Test - {props.subject.mark}%</p>
            <p>Exam - 89%</p>
            <p>Average - 94%</p>
            <p>className Pos. - 1</p>
        </div>
    );
}

export default Card;