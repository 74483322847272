// ? This shows the results lists. For admins. /panel/results

import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { MutatingDots } from 'react-loader-spinner';
import '../../css/Users/listing.css'

const Results_list = () => {

    const [resultsList, setResultsList] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const config = {
            method: 'post',
            url: 'https://lis-api.exion.xyz/api/results/getresultlist',
            withCredentials: true,
        }

        axios(config)
            .then(response => {
                console.log(response.data)
                if (response.data.valid === false) return navigate('/login');
                if (response.data.valid === true) return;
                if (response.data.admin === false) navigate('/results')
                if (response.data.admin === true) return setResultsList(response.data.list);
            })
    }, []);


    return (
        <section className="main">
            <div className="users-container">
                <div className="header-bar">
                    <p>Results</p>
                    <div className="users-header-right">
                        <input id="search" type="text" className="users-search" />
                        <button className="users-create-btn" onClick={() => {}}>Search</button>
                        <button className="crt-btn" onClick={() => navigate('/panel/results/create')}>+</button>
                    </div>
                </div>

                <div className="users-table-container">
                    {resultsList ?

                        (resultsList.length > 0) ?

                            <table className="users-table">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Grade</th>
                                        <th>Session</th>
                                        <th>Term</th>
                                        <th>Assessment</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {resultsList.map((result) => {

                                        return (

                                            <tr>
                                                <td>
                                                    <code><u><a style={{ "cursor": "pointer" }} onClick={() => navigate(`/panel/results/${result._id}`)}>{result._id}</a></u></code>
                                                </td>
                                                <td>{result.grade}</td>
                                                <td>{result.session}</td>
                                                <td>{result.term}</td>
                                                <td>{(result.assessment === "tests") ? "Test":"Exam"}</td>
                                                <td>{(result.status === "not-published") ? "Not published":"Published"}</td>
                                            </tr>

                                        )

                                    })}

                                </tbody>
                            </table>

                            :
                            <h3 style={{ "color": "#FF5555", "marginBottom": "1rem" }}>No results were found, you can create a result by clicking the "+" button at the top.</h3>

                        :

                        <div
                            style={{
                                height: "100",
                                margin: "2rem",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                justifySelf: "center",
                                alignItems: "center"
                            }}
                        >
                            <MutatingDots color="#fff" secondaryColor='#01c38d' height="100" width="100" />
                            <h2>Fetching Users</h2>
                        </div>

                    }

                </div>
            </div>
        </section>
    )
}

export default Results_list