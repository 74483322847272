// ? Page to create users for admins, /panel/admin/users/create

import React, { useEffect, useState } from 'react'
import '../../css/Users/create_users.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { MutatingDots } from 'react-loader-spinner';

const Create_User = () => {
  const [student, setStudent] = useState(false);
  const [teacher, setTeacher] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [language, setLanguage] = useState(false);
  const [senior, setSenior] = useState(false);
  const [subjectsCount, setSubjectsCount] = useState(["Sub"]);
  const [errors, setErrors] = useState({});
  const [processing, setProcessing] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {

    const config = {
      method: 'post',
      url: 'https://lis-api.exion.xyz/api/services/sessions',
      withCredentials: true,
    };

    axios(config)
      .then(response => {
        console.log(response.data)
        if (!response.data.valid) return navigate('/login');
        if (response.data.valid) {
          if (response.data.admin === false) return navigate('/results');
          if (response.data.admin === true) {
            return setAuthenticated(true);
          }
        }
      });

  }, []);

  const onFormSubmit = (() => {
    setProcessing(true);

    const subjects = document.getElementsByClassName("t_s");
    const subjectsArr = [];

    if (document.getElementsByClassName("teachi"))
      Array.from(subjects).forEach((subject) => {
        subjectsArr.push(subject.value);
      });

    const payload = {
      email: document.getElementById("email").value,
      f_name: document.getElementById("f_name").value,
      l_name: document.getElementById("l_name").value,
      address: document.getElementById("address").value,
      gender: document.getElementById("gender").value,
      isStudent: document.getElementById("student").value,
      parent_phone: document.getElementById("guardian_phone")?.value,
      academic_level: document.getElementById("academic_level")?.value,
      isTeacher: document.getElementById("teacher").value,
      teacher_phone: document.getElementById("teacher_phone")?.value,
      teaching_subjects: subjectsArr,
      isAdmin: document.getElementById("admin").value,
      password: document.getElementById("pw").value,
    }

    // ? The subject student selected (grades 7-10), PE/CS/ART/DT
    if (senior) {
      payload.special_subject = document.getElementById('special_subject').value;
    }

    if (language) {
      payload.second_language = document.getElementById('language').value;
    }

    console.log(payload);

    const config = {
      method: 'post',
      url: 'https://lis-api.exion.xyz/api/admin/createUser',
      withCredentials: true,
      params: payload
    }

    axios(config)
      .then((response) => {
        console.log(response)
        if (response.data.valid === false) return navigate('/login');
        if (response.data.valid === true) return;
        if (response.data.admin === false) navigate('/results')
        if (response.data.admin === true) {

          if (response.data.status === "error") {
            setProcessing(false);

            if (response.data.type === "appearance") return setErrors(response.data.details);
            if (response.data.type === "validity") return setErrors(response.data.details);

          }

          if (response.data.status === "ok") {

            setProcessing(false);
            navigate('/panel/admin/users', { state: { from: "user_crt_success", id: response.data.id } });

          }

        }
      })

  });

  const dropdownHandler = (dropdown, value) => {
    if (dropdown === "student") {
      if (value === "yes") return setStudent(true);
      if (value === "no") return setStudent(false);
    }

    if (dropdown === "teacher") {
      if (value === "yes") return setTeacher(true);
      if (value === "no") return setTeacher(false);
    }

    if (dropdown === "admin") {
      if (value === "yes") return setAdmin(true);
      if (value === "no") return setAdmin(false);
    }


    if (dropdown === "level") {
      // Setting special subject (DT/COM/ART)
      if (["7", "8", "9", "10", "11", "12"].includes(value)) {
        setSenior(true)
      } else setSenior(false);

      // Setting language subject
      if (["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"].includes(value)) {
        setLanguage(true);
      } else setLanguage(false);
    }

  }

  return (
    <section className="main">
      {authenticated ?

        processing ?
          <div className="processing-overlay">
            <div
              style={{
                height: "100",
                margin: "2rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                justifySelf: "center",
                alignItems: "center"
              }}
            >
              <MutatingDots color="#fff" secondaryColor='#01c38d' height="100" width="100" />
              <h2>Hold on</h2>
            </div>
          </div>
          :

          <div className="containers-wrapper">
            <div className="inputs-container" id='identity'>
              <div className="inputs-container-header">
                <h3>Identity</h3>
              </div>

              <div className="inputs-group">

                <label htmlFor="email">Email</label>
                <input className={errors.email ? "error-inp" : ""} id="email" type="email" name='email' />

                <label htmlFor="f_name">First Name</label>
                <input className={errors.f_name ? "error-inp" : ""} id="f_name" type="text" name='f_name' />

                <label htmlFor="l_name">Last Name</label>
                <input className={errors.l_name ? "error-inp" : ""} id="l_name" type="text" name='l_name' />

                <label htmlFor="address">Address</label>
                <input className={errors.address ? "error-inp" : ""} type="text" name="address" id='address' />

                <label htmlFor="gender">Gender</label>
                <select name="gender" id="gender">
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>

                <button className='submit' onClick={() => onFormSubmit()}>Create User</button>

              </div>
            </div>

            <div className="inputs-container" id='permissions'>
              <div className="inputs-container-header">
                <h3>Permissions</h3>
              </div>

              <div className="inputs-group">

                <label htmlFor="student">Student</label>
                <select onChange={(e) => dropdownHandler('student', e.target.value)} className={errors.student ? "error-inp" : ""} name="student" id="student">
                  <option value="no" onSelect={() => setStudent(false)}>No</option>
                  <option value="yes" onSelect={() => setStudent(true)}>Yes</option>
                </select>

                {student ?

                  <>

                    <label htmlFor="guardian_phone">Guardian/Parent's Phone</label>
                    <input className={errors.parent_phone ? "error-inp" : ""} type="text" name="parent_phone" id="guardian_phone" />

                    <label htmlFor="academic_level">Academic Level</label>
                    <select onChange={(e) => dropdownHandler('level', e.target.value)} name="academic_level" id="academic_level">
                      <option value="KG1">Kindergarten 1</option>
                      <option value="KG2">Kindergarten 2</option>
                      <option value="1">Grade 1</option>
                      <option value="2">Grade 2</option>
                      <option value="3">Grade 3</option>
                      <option value="4">Grade 4</option>
                      <option value="5">Grade 5</option>
                      <option value="6">Grade 6</option>
                      <option value="7">Grade 7</option>
                      <option value="8">Grade 8</option>
                      <option value="9">Grade 9</option>
                      <option value="10">Grade 10</option>
                      <option value="11">Grade 11</option>
                      <option value="12">Grade 12</option>
                    </select>

                    {language ?

                      <>
                        <label htmlFor="language">Language</label>
                        <select name="language" id="language">
                          <option value="fr">French</option>
                          <option value="ar">Arabic</option>
                        </select>
                      </>

                      : ""}

                    {senior ?

                      <>
                        <label htmlFor="special_subject">Special Subject</label>
                        <select name="special_subject" id="special_subject">
                          <option value="cs">Computer Science</option>
                          <option value="dt">Design & Techonology</option>
                          <option value="art">Art</option>
                          <option value="pe">Physical Education</option>
                        </select>
                      </>

                      :

                      ""

                    }

                  </>

                  :

                  ""

                }

                <label htmlFor="teacher">Teacher</label>
                <select onChange={(e) => dropdownHandler('teacher', e.target.value)} name="teacher" id="teacher">
                  <option value="no" onSelect={() => setTeacher(false)}>No</option>
                  <option value="yes" onSelect={() => setTeacher(true)}>Yes</option>
                </select>
                {/* <p className='note'>Teachers get emails from parents</p> */}

                {teacher ?

                  <>

                    <label htmlFor="teacher_phone">Teacher's Phone</label>
                    <input className={errors.teacher_phone ? "error-inp" : ""} type="text" name="teacher_phone" id="teacher_phone" />

                    <label htmlFor="teaching_subjects">Subjects</label>
                    <div className="inputs-flex">

                      {subjectsCount.map(() => {

                        return (
                          <select className={"t_s" + (errors.subjects ? " error-inp" : "")} name="teaching_subjects" id="teaching_subjects">
                            <option value="physics">Physics</option>
                            <option value="chemistry">Chemistry</option>
                            <option value="biology">Biology</option>
                            <option value="pe">Physical Education</option>
                            <option value="economics">Economics</option>
                            <option value="maths">Maths</option>
                            <option value="science">Science</option>
                            <option value="french">French</option>
                            <option value="arabic">Arabic</option>
                            <option value="english">English</option>
                            <option value="cs">Computer Science</option>
                            <option value="arts">Arts and Craft</option>
                          </select>
                        )

                      })}

                      <button onClick={() => setSubjectsCount([...subjectsCount, "Sub"])}>+</button>
                    </div>
                  </>

                  :

                  ""

                }


                {/* Add more later */}

                <label htmlFor="admin">Administrator</label>
                <select onChange={(e) => dropdownHandler('admin', e.target.value)} name="admin" id="admin">
                  <option value="no" onSelect={() => setAdmin(false)}>No</option>
                  <option value="yes" onSelect={() => setAdmin(true)}>Yes</option>
                </select>
                {/* <p>Grants user access to critical features of the panel</p> */}

              </div>
            </div>

            <div className="inputs-container" id='password'>

              <div className="inputs-container-header">
                <h3>Password</h3>
              </div>

              <div className="inputs-group">
                
                <div className="note-warn">
                  Providing a user password is optional. New user emails prompt users to create a password the first time they login. If a password is provided here you will need to find a different method of providing it to the user.
                </div>

                <label htmlFor="password">Password</label>
                <input className={errors.password ? "error-inp" : ""} type="text" name='password' id='pw' />
                {errors.password ? <p style={{ "color": "#FF5555" }}>Passwords must be atleast <br /> 8 characters in length</p> : ""}

              </div>

            </div>

          </div>

        :

        <div
          style={{
            height: "100",
            margin: "2rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            justifySelf: "center",
            alignItems: "center"
          }}
        >
          <MutatingDots color="#fff" secondaryColor='#01c38d' height="100" width="100" />
          <h2>Authenticating</h2>
        </div>

      }
    </section>
  )
}

export default Create_User