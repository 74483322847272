// ? Page where admins can create results for a session. /panel/results/create

import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MutatingDots } from 'react-loader-spinner';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from 'sweetalert2';
import '../../css/Users/listing.css'

const Results_create = () => {

    const [processing, setProcessing] = useState(false);
    const [students, setStudents] = useState(null);
    const [error, setError] = useState(null);
    const [result_id, setResultID] = useState(null);
    const navigate = useNavigate();

    const submitSession = () => {
        // Getiting all input values from session selection form
        const grade = document.getElementById('grade');
        const session = document.getElementById('session');
        const term = document.getElementById('term');
        const type = document.getElementById('type');

        console.log(grade);

        const config = {
            method: 'post',
            url: `https://lis-api.exion.xyz/api/results/createresult?enquiry=check`,
            withCredentials: true,
            params: { grade: grade.value, session: session.value, term: term.value, type: type.value }
        }

        setProcessing(true)

        axios(config)
            .then(response => {
                console.log(response.data)
                if (response.data.valid === false) return navigate('/login');
                if (response.data.valid === true) return;
                if (response.data.admin === false) navigate('/results')
                if (response.data.admin === true) {
                    if (response.data.status === "ok") {

                        console.log("Return is OK.")

                        document.getElementById('submit-session').remove();

                        // Disable dropdowns
                        grade.disabled = true;
                        session.disabled = true;
                        term.disabled = true;
                        type.disabled = true;

                        setStudents(response.data.students)
                        setResultID(response.data.result_id);
                        setProcessing(false);
                    } else if (response.data.status === "warn") {
                        if (response.data.students) {
                            setStudents(response.data.students)
                            setResultID(response.data.result_id);
                        };
                        setError(response.data.message)
                        setProcessing(false);
                    }
                };
            })
    }

    const actionsHandler = (action) => {
        if (action === "delete") {

            const code = Math.floor(100000 + Math.random() * 900000);

            const config = {
                method: 'post',
                url: `https://lis-api.exion.xyz/api/results/delete`,
                withCredentials: true,
                params: { type: "group", result_id }
            }

            Swal.fire({
                title: 'Confirmation',
                html: `Enter the following code to delete this result. <b>${code}</b>.`,
                input: 'text',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `Delete result`,
                showLoaderOnConfirm: true,
                preConfirm: (input) => {
                    console.log(input);
                    if (parseInt(input) !== code) return Swal.showValidationMessage("Code does not match. Try again.")
                    setProcessing(true);
                    axios(config)
                        .then(response => {
                            console.log(response.data)
                            if (response.data.valid === false) return navigate('/login');
                            if (response.data.valid === true) return;
                            if (response.data.admin === false) navigate('/results')
                            if (response.data.admin === true) {
                                if (response.data.status === 'ok') {
                                    console.log('Something')
                                    setProcessing(false);
                                    Swal.fire({
                                        title: `Result Deleted!`,
                                        text: `This result has been sent into deletion state and will be deleted in 30 days.`,
                                        icon: 'success',
                                        allowOutsideClick: () => navigate('/panel/results', { state: { from: 'result-delete' } })
                                    }).then(() => {
                                        navigate('/panel/results', { state: { from: 'results-delete' } });
                                    });
                                }
                            }
                            // else if (response.data.status === 'error') {
                            //     if (response.data.message === 'unexpectederr') {
                            //         Swal.fire({
                            //             title: `We hit a road block...`,
                            //             text: `An unexpected error occured. Please try again in a bit!`,
                            //             icon: "error"
                            //         })
                            //     }
                            // }
                        })
                        .catch(error => {
                            Swal.showValidationMessage(
                                `Request failed: ${error}`
                            )
                        })
                }
            })
        }

        if (action === "publish") {

            const config = {
                method: 'post',
                url: `https://lis-api.exion.xyz/api/results/publish`,
                withCredentials: true,
                params: { result_id }
            }

            Swal.fire({
                title: 'Confirmation',
                text: `Are you sure you want to publish these results?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `Yes, publish it!`,
                showLoaderOnConfirm: true,
                preConfirm: (input) => {
                    setProcessing(true);
                    axios(config)
                        .then(response => {
                            console.log(response.data)
                            if (response.data.valid === false) return navigate('/login');
                            if (response.data.valid === true) return;
                            if (response.data.admin === false) navigate('/results')
                            if (response.data.admin === true) {
                                if (response.data.status === 'ok') {
                                    setProcessing(false);
                                    Swal.fire({
                                        title: `Result Published!`,
                                        text: `This result has been published and parents have been informed via email.`,
                                        icon: 'success',
                                    })
                                }

                                if (response.data.status === 'exists') {

                                    setProcessing(false);
                                    Swal.fire({
                                        title: `Results already published!`,
                                        icon: 'error',
                                    })

                                }
                            }
                            // else if (response.data.status === 'error') {
                            //     if (response.data.message === 'unexpectederr') {
                            //         Swal.fire({
                            //             title: `We hit a road block...`,
                            //             text: `An unexpected error occured. Please try again in a bit!`,
                            //             icon: "error"
                            //         })
                            //     }
                            // }
                        })
                        .catch(error => {
                            Swal.showValidationMessage(
                                `Request failed: ${error}`
                            )
                        })
                }
            })

        }
    }

    return (
        <section className="main">
            {processing ?
                <div className="processing-overlay">
                    <div
                        style={{
                            height: "100",
                            margin: "2rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            justifySelf: "center",
                            alignItems: "center"
                        }}
                    >
                        <MutatingDots color="#fff" secondaryColor='#01c38d' height="100" width="100" />
                        <h2>Hold on</h2>
                    </div>
                </div>
                :
                ""
            }
            <div className="users-container">
                <div className="header-bar">
                    <p>Session <span style={{ "marginLeft": "1.5rem", "color": "#FF5555", "fontSize": "medium" }}>{error}</span></p>
                </div>

                <div style={{ "marginBottom": "1.5rem" }} className="results-inps-flex">
                    <div className="results-crt-inp-grp">
                        <label htmlFor="grade">Grade</label>
                        <select className="adjust" name="grade" id="grade">
                            <option value="KG1">Kindergarten 1</option>
                            <option value="KG2">Kindergarten 2</option>
                            <option value="1">Grade 1</option>
                            <option value="2">Grade 2</option>
                            <option value="3">Grade 3</option>
                            <option value="4">Grade 4</option>
                            <option value="5">Grade 5</option>
                            <option value="6">Grade 6</option>
                            <option value="7">Grade 7</option>
                            <option value="8">Grade 8</option>
                            <option value="9">Grade 9</option>
                            <option value="10">Grade 10</option>
                            <option value="11">Grade 11</option>
                            <option value="12">Grade 12</option>
                        </select>
                    </div>

                    <div className="results-crt-inp-grp">
                        <label htmlFor="session">Session</label>
                        <select className="adjust" name="session" id="session">
                            <option value="2022-2023">Current (2022-2023)</option>
                            <option value="2021_2022">2021-2022</option>
                            <option value="2020_2021">2020-2021</option>
                            <option value="2019_2020">2019-2020</option>
                            <option value="2018_2019">2018-2019</option>
                            <option value="2017_2018">2017-2018</option>
                            <option value="2016_2017">2016-2017</option>
                        </select>
                    </div>

                    <div className="results-crt-inp-grp">
                        <label htmlFor="term">Term</label>
                        <select className="adjust" name="term" id="term">
                            <option value="1">Term 1</option>
                            <option value="2">Term 2</option>
                            <option value="3">Term 3</option>
                        </select>
                    </div>

                    <div className="results-crt-inp-grp">
                        <label htmlFor="type">Type</label>
                        <select className="adjust" name="type" id="type">
                            <option value="tests">Mid-term tests</option>
                            <option value="exams">Exams</option>
                        </select>
                    </div>
                </div>

                <button id="submit-session" style={{ "marginTop": "0" }} className="submit" onClick={() => submitSession()}>Next</button>
            </div>

            {!students ?

                <></>

                :

                <>
                    <div className="users-container">
                        <div className="header-bar">
                            <p>Students</p>
                        </div>

                        <div className="users-table-container">
                            {students ?

                                (students.length > 0) ?

                                    <table className="users-table">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Email</th>
                                                <th>Student Name</th>
                                                <th>Academic Level</th>
                                                <th>Results</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {students.map((user) => {

                                                return (

                                                    <tr>
                                                        <td>
                                                            <code>{user._id}</code>
                                                        </td>
                                                        <td>
                                                            <a href="#">{user.email}</a>
                                                        </td>
                                                        <td className='name'>{user.first_name} {user.last_name}</td>
                                                        <td className='class'>{user.grade}</td>
                                                        <td><button style={{ "margin": "0" }} onClick={() => navigate(`/panel/results/${result_id}/${user._id}`)} className="submit"><FontAwesomeIcon icon="fa-solid fa-paste" /></button></td>
                                                    </tr>

                                                )

                                            })}

                                        </tbody>
                                    </table>

                                    :
                                    <h3 style={{ "color": "#FF5555", "marginBottom": "1rem" }}>No students were found, you can create a student here</h3>

                                :

                                <div
                                    style={{
                                        height: "100",
                                        margin: "2rem",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        justifySelf: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <MutatingDots color="#fff" secondaryColor='#01c38d' height="100" width="100" />
                                    <h2>Fetching Users</h2>
                                </div>

                            }

                        </div>

                    </div>

                    <div className="users-container">
                        <div className="header-bar">
                            <p>Actions</p>
                        </div>
                        <div className="allactions-container">

                            <div style={{ "borderBottom": "2px solid hsla(226, 20%, 21%, 1)" }} className="action-container">
                                <div className="action-details">
                                    <h1>Publish result</h1>
                                    <p>Publishes the result. Students will be notified via email that their results are out.</p>
                                </div>
                                <button onClick={() => actionsHandler('publish')} style={{ "marginTop": "0" }} className="submit">Publish</button>
                            </div>
                            <div className="action-container">
                                <div className="action-details">
                                    <h1>Delete result</h1>
                                    <p>This will send the result into a deletion state. Will be deleted after 30 days.</p>
                                </div>
                                <button onClick={() => actionsHandler('delete')} style={{ "marginTop": "0", "background": "#FF5555" }} className="submit">Delete</button>
                            </div>

                        </div>
                    </div>

                </>
            }

        </section>
    )
}

export default Results_create