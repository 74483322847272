import { BrowserRouter as Router, Routes as Switch, Route } from 'react-router-dom';
import Content from './components/Content';
import Login from './pages/Login'
import WithoutNav from './components/Withoutnav'
import WithNav from './components/Withnav';
import PSET from './pages/Pset';

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { fab } from '@fortawesome/free-brands-svg-icons'
// import { faScroll, faUserFriends } from '@fortawesome/free-solid-svg-icons'

// library.add(faScroll, faUserFriends)

function App() {
// ! Do what snowman said. Shenanigans
  return (
    <div className="App">
      <div id="content">
        <Router>
          <Switch>
            <Route element={<WithoutNav />}>
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/account/pset/:code" element={<PSET />} />
            </Route>
            <Route element={<WithNav />}>
              <Route exact path="/" element={<Content targ="/" />} />

              // STUDENT PATHS
              <Route path="/results" element={<Content targ="results" />} />
              <Route path="/about" element={<Content targ="about" />} />
              <Route path="/news" element={<Content targ="news" />} />
              <Route path="/contact" element={<Content targ="contact" />} />

              // TEACHER/ADMIN PATHS
              <Route path="/panel/students" element={<Content targ="admin_students" />} />
              <Route path="/panel/students/:id" element={<Content targ="manage_student" />} />
              <Route path="/panel/students/administration" element={<Content targ="administer_student" />} />
              <Route path="/panel/results" element={<Content targ="admin_results_search" />} />
              <Route path="/panel/results/create" element={<Content targ="admin_results_create" />} />
              <Route path="/panel/results/:result_id" element={<Content targ="admin_results_view" />} />
              <Route path="/panel/results/:result_id/:student_id" element={<Content targ="admin_results_edit" />} />

              // PANEL ADMIN ROUTES
              <Route path="/panel/admin/users" element={<Content targ="panel_users" />} />
              <Route path="/panel/admin/users/create" element={<Content targ="panel_users_create" />} />

            </Route>
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;