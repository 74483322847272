import { useState, useEffect } from 'react';
import '../css/login.css'
import axios from 'axios';
import $ from 'jquery';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie'
import qs from 'qs';
import { MutatingDots } from 'react-loader-spinner';

const PSET = () => {
    const [loadError, setLoadError] = useState(null);
    const [loading, setLoading] = useState(true);

    const navigation = useNavigate();
    const { code } = useParams();

    // Check if user is already logged in

    useEffect(() => {
        const data = qs.stringify({
            session: Cookies.get('session')
        });

        const config = {
            method: 'post',
            url: `https://lis-api.exion.xyz/api/account/pset/${code}?type=check`,
            withCredentials: true,
            data: data,
        };

        axios(config)
            .then(response => {
                console.log(response.data)
                if (response.data.valid) return navigation('/results');
                if (response.data.status === 'error') {
                    setLoadError(response.data.error)
                    return setLoading(false);
                };
                if (response.data.status === "ok") return setLoading(false);
            });
    }, []);

    const [errors, setErrors] = useState(null);
    const [success, setSuccess] = useState(null);

    const formHandler = ((e) => {
        const $form = $('#login')
        e.preventDefault();

        const button = document.getElementById('lsubmit');
        const icon = document.getElementById('icon');

        button.classList.add('disabled');
        icon.setAttribute('class', 'fas fa-lock icon-left');

        const options = {
            method: 'post',
            url: `https://lis-api.exion.xyz/api/account/pset/${code}?type=pset_conclude`,
            withCredentials: true,
            data: $form.serialize(),
        }

        console.log($form.serialize())

        axios(options)
            .then(response => {

                button.classList.remove('disabled');
                icon.setAttribute('class', 'fa fa-key')

                console.log(response.data)
                if (response.data.status === "ok") {
                    // Remove all previous errors
                    setErrors(null);
                    // Update success state to true
                    setSuccess(true);
                    // Redirect user to page
                    setTimeout(() => {
                        navigation('/results')
                    }, 1500);
                };
                if (response.data.status === "error") {
                    // Update error state
                    setErrors(response.data)
                };
            })
    });

    return (
        <div className='yoho'>
            <div className="login-container">
                <form className="login-form" id="login">
                    {loading ?
                        <div
                            style={{
                                height: "100",
                                margin: "2rem",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                justifySelf: "center",
                                alignItems: "center"
                            }}
                        >
                            <MutatingDots color="#191e29" secondaryColor='#01c38d' height="100" width="100" />
                            <h2>Loading Form</h2>
                        </div>
                        :
                        loadError ?
                            <h2 className="main-error">{loadError}</h2>
                            :
                            <>
                                <div className="login-header">
                                    <img src="https://exion.xyz/lis.png" alt="logo" />
                                    <h1>LIS</h1>
                                </div>
                                {(success !== null) ? (success === true) ? <h4 className='success'>Logging you in..</h4> : <h4 className='main-error'>Something went wrong</h4> : ""}
                                <label htmlFor="password">Password</label>
                                {errors ? (errors.password) ? <label style={{ "width": "300px", "lineHeight": "1.4", "marginBottom": "10px"}} className="error" htmlFor="password">{errors.password}</label> : "" : ""}
                                <input type="password" name="password" id="password" placeholder='Password' />
                                <label htmlFor="password">Confirm password</label>
                                {errors ? (errors.confirmation) ? <label style={{ "width": "300px", "lineHeight": "1.4", "marginBottom": "10px"}} className="error" htmlFor="confirmation">{errors.confirmation}</label> : "" : ""}
                                <input type="password" name="confirmation" id="confirmation" placeholder='Confirm Password' />
                                <div className="rme">
                                    <input type="checkbox" name="rme" id="rme" value="true" />
                                    <label htmlFor="rme">Remember this device</label>
                                </div>
                                <button id="lsubmit" onClick={(e) => formHandler(e)}><i id="icon" className="fa fa-key"></i> Set Password</button>
                            </>
                    }
                </form>
            </div>
        </div>
    );
};

export default PSET;